import React from 'react'
import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI"
import ModalForm from "@whitecobalt/tungsten/esm/components/Modal/Form";
import ModalPreview from "@whitecobalt/tungsten/esm/components/Modal/Preview";
import FormManager from '@whitecobalt/tungsten/FormManager';
import Toaster from '@whitecobalt/tungsten/Toaster';
import branding from '@config/branding';
import FileField from '@whitecobalt/tungsten/esm/components/Fields/FileField';
import { useDisconnectedJob } from '@whitecobalt/tungsten/esm/common/hooks/useDisconnectedJob';
import Icon from '@whitecobalt/tungsten/esm/components/Icon';
import worldTrigger from 'world-trigger';

export const useMenuPopupUploadClient = () => {
    const request = useAuthAPI('/api/ImportCRMContact')
    const requestDisconnected = useDisconnectedJob({
        showOutputMessage: false,
        showToaster: true,
        toasterProps: {
            placement: "bottom-right",
            variant: 'success',
            progressVariant: 'success',
            progress: 5,
            body: (
                <p className="mb-3">Processing, please wait...</p>
            )
        }
    })

    return () => {
        // after the promise is done we need to set another one that is why we recursive when the request is failed
        const recursivePromptForm = () => {
            ModalForm({
                key: 'upload-crm-client',
                title: 'Upload Contact',
                titleIcon: `fa fa-cloud-upload-alt fa-lg`,
                submit: 'Upload',
                body: (
                    <> 
                        <FormManager.Input
                            as={FileField}
                            name="fileKey"
                            label="CSV File"
                            placeholder="Drag & drop or browse csv file here"
                            accept=".csv"
                            rules="custom_mimes:csv"
                            // actions={[
                            //     {
                            //         label: <Icon name='eye' />,
                            //         title: 'previews',
                            //         onClick: (e: any) => {
                            //             ModalPreview({
                            //                 fileKey: e.fileKey,
                            //                 types: e.types
                            //             })
                            //         }
                            //     }
                            // ]}
                            types={{
                                '.csv': <Icon name="file-csv" />,
                                spreadsheet: <Icon name="file-excel" />,
                                default: <Icon name="file" />
                            }}
                            />
                    </>
                )
            })
            .then((event) => {
                if(event.isSubmit) {

                    if(event.isReady() === false) {
                        recursivePromptForm()
                        event.removeLoading()
                        return;
                    }
                    
                    const FORM_DATA = event.json()
                    
                    const payload = {
                        fileKey: FORM_DATA.fileKey[0]
                    }

                    const toast = Toaster('Uploading')
                    
                    request.call({ data: payload }).then((response) => {
                        if(response.data.success) {
                            event.closeModal()
                            toast.void()
                            requestDisconnected.call(response.data.disconnectedJobID).then(() => {
                                worldTrigger.dispatchTrigger('customers.users.reload')
                                worldTrigger.dispatchTrigger('customers.reload')
                            })
                        } else {
                            toast.fail(response.data.message || branding.messages.fail)
                            FormManager.setBackendValidation(event, response.data.errors)
                            event.setValidationMessages(response.data.errors?.map((error: any) => error.errorMessage))
                            recursivePromptForm()
                        }
                    })
                    .catch((error) => {
                        recursivePromptForm()
                        const errorMessage = error?.response?.data?.message || branding.messages.error
                        event.setError(errorMessage)
                        toast.error(errorMessage)
                    })
                    .finally(() => {
                        event.removeLoading()
                    })
                }
            })
        }

        recursivePromptForm()
    }
}