import branding from "@config/branding";
import React, {useMemo} from "react";
import {useHtmlClassService} from "../../_core/MetronicLayout";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          {typeof branding.brandingFooter === "string" ? (
            <a
              href={branding.brandSite}
              target="_blank"
              rel="noopener noreferrer"
              className="text-dark-75 text-hover-primary"
              dangerouslySetInnerHTML={{
                __html: branding.brandingFooter
              }}
            />
          ) : (
            <>
              &copy;{" "} <span className="text-muted font-weight-bold mr-2">{`${(parseInt(today.toString()) - 1).toString()}-${today.toString()} `} </span> 
              <a
                href={branding.brandSite}
                target="_blank"
                rel="noopener noreferrer"
                className="text-dark-75 text-hover-primary"
              >
              {branding.brandName} {" "}
              </a>
              {branding.brandingFooter && (
                <>
                  - Technology provided by{" "} 
                  <a
                    href="https://www.whitecobalt.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-dark-75 text-hover-primary"
                  >
                    White Cobalt Ltd
                  </a>   
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
