import { gql } from "@apollo/client"
import { BusinessProcessDiagramDraggableQuery, ChangeRequestDraggableQuery, FeatureDraggableQuery, TaskDraggableQuery, TestMatrixDraggableQuery } from "@services/query/DraggableQueries"
import { 
  EmailTemplatesDropdownQuery, 
  TaskTypeDropdownQuery, 
  AutoEmailResponseTypeDropdownQuery, 
  TargetCompaniesDropdownQuery,
  AutoEmailBoxesDropdownQuery,
  AutoEmailCampaignStatusesDropdownQuery,
  EnvironmentStatusesDropdownQuery,
  EnvironmentModesdDropdownQuery,
  JobRolesDropdownQuery,
  OrganisationsDropdownQuery,
  SentryProjectDropdownQuery,
  OrganisationTypesDropdownQuery,
  ParameterTypesDropdownQuery,
  PermissionsDropdownQuery,
  UserTypesDropdownQuery,
  MobileCountriesDropdownQuery,
  CountriesDropdownQuery,
  TitlesDropdownQuery,
  ApplicationAreasDropdownQuery,
  UsersDropdownQuery,
  AccountSystemContactsDropdownQuery,
  InvoicingModesDropdownQuery,
  BudgetAccountCodeTypesDropdownQuery,
  BudgetAccountCodesDropdownQuery,
  BudgetVersionDropdownQuery,
  TaskStatusesDropdownQuery,
  QuickTaskTypesDropdownQuery,
  AffectedAreasDropdownQuery,
  SprintStatusesDropdownQuery,
  FeedbackStatusesDropdownQuery,
  FeatureStatusesDropdownQuery,
  TasksDropdownQuery,
  FeaturesDropdownQuery,
  ChangeRequestsDropdownQuery,
  SprintsDropdownQuery,
  BusinessProcessDiagramStepTypesDropdownQuery,
  BusinessProcessDiagramTypesDropdownQuery,
  ProjectsDropdownQuery,
  BusinessProcessDiagramsDropdownQuery,
  PositionAgreementsDropdownQuery,
  DepartmentsDropdownQuery,
  TestMatrixMethodsDropdownQuery,
  TestMatrixTypesDropdownQuery,
  ColourPalettesDropdownQuery,
  FontAwesomeIconsDropdownQuery,
  FeedbacksDropdownQuery
} from "@services/query/DropdownQueries"
import { IntroducerFeeDropdown, TargetCompanyCategoriesDropDownQuery, TargetCompanyClosedReasonsDropDownQuery, TargetCompanyGroupsDropDownQuery, TargetCompanySourcessDropDownQuery, TargetCompanyStatusesDropDownQuery } from "@services/query/grid/custom/CRMPagesQuery"
import { DashboardListGridFormattingOptionsDropdownQuery } from "@services/query/grid/DashboardListGridsQuery"
import { DashboardGroupsDropdownQuery } from "@services/query/grid/DashboardQuery"
import { TemplateModesDropdownQuery } from "@services/query/grid/EmailTemplatesQuery"
import { PaymentAccountsDropdownQuery } from "@services/query/grid/PaymentAccountsQuery"
import { PermissionPresetsDropdownQuery } from "@services/query/grid/PermissionsQuery"
import { ReportFormattingOptionsDropdownQuery, ReportGroupsDropdownQuery, ReportParameterTypesDropdownQuery, ReportParameterValuesDropdownQuery, ReportWildcardModesDropdownQuery } from "@services/query/grid/ReportsQuery"

export type EntityCachePropType = { 
    ID: number; 
    EntityName: string;
}

export const EntityCachesQuery = gql`
query (
    $where: EntityCacheFilterInput
  ) {
    EntityCaches (
        where: $where
    ) {
      totalCount
      items {
        ID
        EntityName
      }
    }
  }
`

export const cacheQueries = {
  FontAwesomeIcon: [
    FontAwesomeIconsDropdownQuery
  ],
  ColourPalette: [
    ColourPalettesDropdownQuery
  ],
  Organisation: [
    OrganisationsDropdownQuery
  ],
  SentryProject: [
    SentryProjectDropdownQuery
  ],
  OrganisationType: [
    OrganisationTypesDropdownQuery
  ],
  ParameterType: [
    ParameterTypesDropdownQuery
  ],
  Permission: [
    PermissionsDropdownQuery
  ],
  JobRole: [
    JobRolesDropdownQuery
  ],
  UserType: [
    UserTypesDropdownQuery
  ],
  Country: [
    MobileCountriesDropdownQuery,
    CountriesDropdownQuery
  ],
  Title: [
    TitlesDropdownQuery
  ],
  ApplicationArea: [
    ApplicationAreasDropdownQuery
  ],
  User: [
    UsersDropdownQuery
  ],
  AccountSystemContact: [
    AccountSystemContactsDropdownQuery
  ],
  InvoicingMode: [
    InvoicingModesDropdownQuery
  ],
  AccountCodeType: [
    BudgetAccountCodeTypesDropdownQuery
  ],
  AccountCode: [
    BudgetAccountCodesDropdownQuery
  ],
  BudgetVersion: [
    BudgetVersionDropdownQuery
  ],
  EmailTemplate: [
    EmailTemplatesDropdownQuery
  ],
  TaskType: [
    TaskTypeDropdownQuery
  ],
  AutoEmailResponseType: [
    AutoEmailResponseTypeDropdownQuery
  ],
  TargetCompany: [
    TargetCompaniesDropdownQuery
  ],
  AutoEmailBox: [
    AutoEmailBoxesDropdownQuery
  ],
  AutoEmailCampaignStatus: [
    AutoEmailCampaignStatusesDropdownQuery
  ],
  EnvironmentStatus: [
    EnvironmentStatusesDropdownQuery
  ],
  EnvironmentMode: [
    EnvironmentModesdDropdownQuery
  ],
  TaskStatus: [
    TaskStatusesDropdownQuery
  ],
  QuickTaskType: [
    QuickTaskTypesDropdownQuery
  ],
  AffectedArea: [
    AffectedAreasDropdownQuery
  ],
  SprintStatus: [
    SprintStatusesDropdownQuery
  ],
  FeedbackStatus: [
    FeedbackStatusesDropdownQuery
  ],
  Feedback: [
    FeedbacksDropdownQuery
  ],
  FeatureStatus: [
    FeatureStatusesDropdownQuery
  ],
  Task: [
    TasksDropdownQuery,
    TaskDraggableQuery
  ],
  Feature: [
    FeaturesDropdownQuery,
    FeatureDraggableQuery
  ],
  ChangeRequest: [
    ChangeRequestsDropdownQuery,
    ChangeRequestDraggableQuery
  ],
  Sprint: [
    SprintsDropdownQuery
  ],
  BusinessProcessDiagramStepType: [
    BusinessProcessDiagramStepTypesDropdownQuery
  ],
  BusinessProcessDiagramType: [
    BusinessProcessDiagramTypesDropdownQuery
  ],
  Project: [
    ProjectsDropdownQuery
  ],
  BusinessProcessDiagram: [
    BusinessProcessDiagramsDropdownQuery,
    BusinessProcessDiagramDraggableQuery
  ],
  PositionAgreement: [
    PositionAgreementsDropdownQuery
  ],
  Department: [
    DepartmentsDropdownQuery
  ],
  DashboardListGridFormattingOption: [
    DashboardListGridFormattingOptionsDropdownQuery
  ],
  DashboardGroup: [
    DashboardGroupsDropdownQuery
  ],
  TemplateMode: [
    TemplateModesDropdownQuery
  ],
  PaymentAccount: [
    PaymentAccountsDropdownQuery
  ],
  PermissionPreset: [
    PermissionPresetsDropdownQuery
  ],
  ReportGroup: [
    ReportGroupsDropdownQuery
  ],
  ReportParameterType: [
    ReportParameterTypesDropdownQuery
  ],
  ReportWildcardMode: [
    ReportWildcardModesDropdownQuery
  ],
  ReportFormattingOption: [
    ReportFormattingOptionsDropdownQuery
  ],
  ReportParameterValue: [
    ReportParameterValuesDropdownQuery
  ],
  TestMatrix: [
    TestMatrixDraggableQuery
  ],
  TestMatrixMethod: [
    TestMatrixMethodsDropdownQuery
  ],
  TestMatrixType: [
    TestMatrixTypesDropdownQuery
  ],
  TargetCompanySource: [
    TargetCompanySourcessDropDownQuery
  ],
  IntroducerFeeStructure: [
    IntroducerFeeDropdown
  ],
  TargetCompanyClosedReason: [
    TargetCompanyClosedReasonsDropDownQuery
  ],
  TargetCompanyStatus: [
    TargetCompanyStatusesDropDownQuery
  ],
  TargetCompanyCategory: [
    TargetCompanyCategoriesDropDownQuery
  ],
  TargetCompanyGroup: [
    TargetCompanyGroupsDropDownQuery
  ]
}