import React from 'react'
import Button from '@whitecobalt/tungsten/Button'
import Icon from '@whitecobalt/tungsten/Icon'
import { SessionActionType, useSession } from '@whitecobalt/tungsten/esm/components/Session';
import { useAuthAPI } from '@whitecobalt/tungsten/esm/common/hooks/useAuthAPI';
import ModalForm from '@whitecobalt/tungsten/esm/components/Modal/Form';
import FormManager from '@whitecobalt/tungsten/esm/components/FormManager';
import Toaster from '@whitecobalt/tungsten/esm/components/Toaster';
import branding from '@config/branding';
import EditorField from "@whitecobalt/tungsten/esm/components/Fields/EditorField";
import EmailSignatureField from "./Field";

const EmailSignature: React.FunctionComponent = () => {
    const [{user}, dispatch] = useSession()

    const request = useAuthAPI("/api/EmailSignature");

    const handler = () => {
        const emailSignature = EditorField.convertHTMLToEditorState(user?.emailSignature || "")
        const recursivePromptForm = () => {
            ModalForm({
                key: "email-signature",
                title: "Edit Email Signature",
                titleIcon: "fa fa-pen fa-lg",
                size: "lg",
                submit: "Save",
                body: (
                    <>
                        <EmailSignatureField value={emailSignature} />
                    </>
                ),
            }).then((event) => {
                if (event.isSubmit) {
                    const FORM_DATA = event.json();

                    const payload = {
                        "emailSignature": EditorField.convertEditorStateToHTML(FORM_DATA.emailSignature),
                    }

                    const toast = Toaster("Updating Email Signature");

                    request
                        .call({ data: payload })
                        .then((response) => {
                            if (response.data.success) {
                                event.closeModal()
                                toast.success(response?.data?.message || 'Success!');
                                dispatch({ 
                                    type: SessionActionType.SET_USER,
                                    payload: {
                                        ...user,
                                        ...payload
                                    }
                                })
                            } else {
                                toast.fail(response?.data?.message || branding.messages.fail);
                                FormManager.setBackendValidation(event, response.data.errors)
                                recursivePromptForm();
                            }
                        })
                        .catch((error) => {
                            recursivePromptForm();
                            event.setError(error?.response?.data?.message || branding.messages.error);
                            toast.error();
                        })
                        .finally(() => {
                            event.removeLoading();
                        })
                }
            })
        }
        recursivePromptForm();
    }

    return (
        <Button
            type="button"
            onClick={handler}
        >
            Edit Email Signature
        </Button>
    )
}

export default EmailSignature