import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLinearToken } from "@whitecobalt/tungsten/hooks/useLinearToken";
import { useReceiveUser } from "@whitecobalt/tungsten/hooks/useReceiveUser";
import branding from "@config/branding";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import './index.scss';
import TimeLogs from "@app/pages/TimeLogs";
import { SessionActionType, useSession } from "@whitecobalt/tungsten/esm/components/Session";
import Loader from "@whitecobalt/tungsten/esm/components/Loader";
import TimeTracker from "@metronic/layout/components/header/TimeTracker";
import Button from "@whitecobalt/tungsten/esm/components/Button";
import Icon from "@whitecobalt/tungsten/esm/components/Icon";
import worldTrigger from "world-trigger";
import Helptip from "@whitecobalt/tungsten/esm/components/Helptip";

export default function ChromeApplication() {
    const [{ loading, user }, dispatch] = useSession();
	useReceiveUser()

    useLayoutEffect(() => {
        document.body.style.minWidth = '500px'
        document.body.style.minHeight = '600px'
    }, [])
    
    useEffect(() => {
        //@ts-ignore
        chrome.cookies.get({ 
            url: process.env.REACT_APP_URL, 
            name: branding.token.name
        },
        //@ts-ignore
        function (cookie) {
            if (cookie) {
                dispatch({
                    type: SessionActionType.SET_TOKEN,
                    payload: cookie.value || null
                })
            } else {
                dispatch({
                    type: SessionActionType.SET_TOKEN,
                    payload: null
                })
            }
        })
    }, [])

    if(loading) {
		return (
			<Loader active hasSpinner={false} className="kt-splash-screen">
				<div className="mb-4"  data-env-impose="true">
					<img src={branding.logo.splash} style={{maxWidth: 250}}/>
				</div>
				<Spinner animation="border" />
			</Loader>
		)
	}

	return (
		<Container fluid className="chrome-container">
            {user ? (
                <>
                    <div className="chrome-container-header">
                        <div className="chrome-container-header-branding">
                            <div>
                                <img src="/media/logos/logo-whitecobalt-inverse.png"/>
                                <span className="ml-2">Time Tracker</span>
                            </div>
                            <div>
                                <Helptip id="time-log-external" description="Show All logs" overlayTriggerProps={{placement: "bottom"}}>
                                    <a href={process.env.REACT_APP_URL + "/time-logs"} target="_blank">
                                        <Icon name="external-link-alt" />
                                    </a>
                                </Helptip>
                                <Helptip id="time-log-sync" description="Sync" overlayTriggerProps={{placement: "bottom"}}>
                                    <a href="#" className="ml-3" onClick={(e) => {
                                        e.preventDefault()
                                        worldTrigger.dispatchTrigger('time.log.sync')
                                        worldTrigger.dispatchTrigger('time.log.reload')
                                    }}>
                                        <Icon name="sync" />
                                    </a>
                                </Helptip>
                            </div>
                        </div>
                        <div className="chrome-container-header-form">
                            <TimeTracker />
                        </div>
                    </div>
                    <TimeLogs />
                </>
            ) : (
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                    <div>
                        <div className="mb-3">
                            <img src="/media/logos/logo-whitecobalt.svg" style={{width: 150}}/>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <Button onClick={() => window.open(process.env.REACT_APP_URL + "/time-logs")}>Log in</Button>
                        </div>
                    </div>
                </div>
            )}
        </Container>
	);
}
