import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import ConditionalRoute from "@whitecobalt/tungsten/ConditionalRoute";
import ErrorBoundary from "@whitecobalt/tungsten/ErrorBoundary";
import Loader from "@whitecobalt/tungsten/Loader";
import { useSession, SessionActionType } from "@whitecobalt/tungsten/Session";
import branding from "@config/branding";
import AuthRoutes from "./pages/Auth";
import { ErrorPage } from "./pages/ErrorPage";
import { ContentRoute, Layout } from "@metronic/layout";
import CustomPublic from "./pages/CustomPublic";
import {
	AvailableTags,
    Users,
    RegisteredUsers,
    SentEmails,
    SentSMS,
	SystemSettings,
	Reports,
	Widgets,
	Dashboard,
	Customers,
	MyCompany,
	Invoices,
	PermissionPreset,
	DocumentTemplates,
	EmailTemplates,
	SMSTemplates,
	TimeLogHistory,
	Bills,
	PaymentRuns,
	PaymentAccounts,
	CRMCompanies,
	CRMGroups,
	CRMIntroducers,
	Departments,
	PositionAgreements,
	BusinessProcessDesigner,
	AwaitingApproval,
	OrganisationalChart,
	Budget,
	Projects,
	EmployeeLeaves,
	TaskAssignment,
	Environments,
	MyTasks,
	AutoEmailCampaigns,
	TimeLogReport,
	SentryDashboard,
	DevPlans,
	EmailResponses,
	UnapprovedFeatureTask,
	AwaitingBudgetaryApproval,
	AwaitingFunctionalApproval,
	AllSprints,
	AllFunctionalSpec,
	AllChangeRequest,
	Charts,
	BackgroundProcesses,
	DisconnectedProcesses,
	Wiki,
	EmailInbox,
	CompanyStandards
} from './pages'
import CacheGQL from "@components/CacheGQL";
import { useRouteCorrection } from "@hooks/useRouteCorrection";
import HTMLToPDFExample from "./pages/CustomPages/HTMLToPDFExample";
import FileManagerDemo from "./pages/CustomPages/FileManagerDemo";
import { useHasMFAPrompt } from "@services/store/global";

const MetronicRoute: any = ContentRoute

const Routes = () => {
	useRouteCorrection()
	const [{ loading, user, others }, dispatch] = useSession();
	const [hasMFAPrompt] = useHasMFAPrompt()
	const hasAuthenthication = !!(
		user &&
		(branding.allowOrganisationSelection === false ||
		(branding.allowOrganisationSelection &&
			others.hasSelectedOrganisation)) &&
		hasMFAPrompt
	)
	const handleRedirect = (redirected: boolean, props: any) => {
		if (redirected) {
			dispatch({
				type: SessionActionType.SET_REDIRECT,
				payload: props.location.pathname
			})
		}
	}

	if(loading) {
		return (
			<Loader active hasSpinner={false} className="kt-splash-screen">
				<div className="mb-4"  data-env-impose="true">
					<img src={branding.logo.splash} style={{maxWidth: 250}}/>
				</div>
				<Spinner animation="border" />
			</Loader>
		)
	}

	return (
		<Switch>
			<Route path="/auth">
				<AuthRoutes />
			</Route>
			<Route path="/public">
				<CustomPublic />
			</Route>
			<ConditionalRoute
				condition={hasAuthenthication}
				onRedirect={handleRedirect}
				redirectTo='/auth/login'
				path="/">
				<CacheGQL>
				<Layout>
					<ErrorBoundary>
						<Suspense fallback={(<Loader active />)}>
							<Switch>
								<MetronicRoute path="/registered-users" component={RegisteredUsers} />
								<MetronicRoute path="/emails" component={SentEmails} />
								<MetronicRoute path="/sms" component={SentSMS} />
								<MetronicRoute path="/syssettings" component={SystemSettings} />
								<MetronicRoute path="/reports" component={Reports} />
								<MetronicRoute path="/widgets" component={Widgets} />
								<MetronicRoute path="/dashboard" component={Dashboard} />
								<MetronicRoute path="/admin/available-tags" component={AvailableTags} />
								<MetronicRoute path="/admin/users" component={Users} />
								<MetronicRoute path="/admin/invoices" component={Invoices} />
								<MetronicRoute path="/admin/bills" component={Bills} />
								<MetronicRoute path="/admin/permission-presets" component={PermissionPreset} />
								<MetronicRoute path="/admin/document-templates" component={DocumentTemplates} />
								<MetronicRoute path="/admin/email-templates" component={EmailTemplates} />
								<MetronicRoute path="/admin/sms-templates" component={SMSTemplates} />
								<MetronicRoute path="/admin/payment-accounts" component={PaymentAccounts} />
								<MetronicRoute path="/admin/payment-runs" component={PaymentRuns} />
								<MetronicRoute path="/admin/background-process" component={BackgroundProcesses} />
								<MetronicRoute path="/admin/disconnected-jobs" component={DisconnectedProcesses} />
								<MetronicRoute path="/time-logs" component={TimeLogHistory} />
								<MetronicRoute path="/time-log-report" component={TimeLogReport} />
								<MetronicRoute path="/budget/actual" component={Budget} />
								<MetronicRoute path="/budget" component={Budget} />
								<MetronicRoute path="/charts" component={Charts} />
								<MetronicRoute path="/inbox" component={EmailInbox} />
								<MetronicRoute path="/wiki" component={Wiki} />
								{/** Custom Pages - Start */}
									<MetronicRoute path="/crm/autoemailresponses" component={EmailResponses} />
									<MetronicRoute path="/organisational-chart/:id" component={OrganisationalChart} />
									<MetronicRoute path="/process/awaiting-approval" component={AwaitingApproval} />
									<MetronicRoute path="/process/diagrams" component={BusinessProcessDesigner} />
									<MetronicRoute path="/team/position-agreements" component={PositionAgreements} />
									<MetronicRoute path="/process/departments" component={Departments} />
									<MetronicRoute path="/customers" component={Customers} />
									<MetronicRoute path="/my-tasks" component={MyTasks} />
									<MetronicRoute path="/crm/groups" component={CRMGroups} />
									<MetronicRoute path="/crm/introducers" component={CRMIntroducers} />
									<MetronicRoute path="/crm/companies" component={CRMCompanies} />
									<MetronicRoute path="/developmentmanagement" component={Projects} />
									<MetronicRoute path="/employee-leaves" component={EmployeeLeaves} />
									<MetronicRoute path="/environments" component={Environments} />
									<MetronicRoute path="/task-schedule" component={TaskAssignment} />
									<MetronicRoute path="/crm/autoemailcampaigns" component={AutoEmailCampaigns} />
									<MetronicRoute path="/sentry-dashboard" component={SentryDashboard} />
									<MetronicRoute path="/dev-plans" component={DevPlans} />
									<MetronicRoute path="/unapproved-feature-tasks" component={UnapprovedFeatureTask} />
									<MetronicRoute path="/awaiting-budgetary" component={AwaitingBudgetaryApproval} />
									<MetronicRoute path="/my-company" component={MyCompany} />
									<MetronicRoute path="/image-manager-demo" component={FileManagerDemo} />
									{branding.services.env !== 'production' && <MetronicRoute path="/html-pdf" component={HTMLToPDFExample} />}

								{/** Customer Routes - Start */}
									<MetronicRoute path="/mycompany" component={MyCompany} />
									<MetronicRoute path="/customer/projects" component={Projects} />
									<MetronicRoute path="/customer/budgetary-approval" component={AwaitingBudgetaryApproval} />
									<MetronicRoute path="/customer/functional-approval" component={AwaitingFunctionalApproval} />
									<MetronicRoute path="/customer/sprints" component={AllSprints} />
									<MetronicRoute path="/customer/change-requests" component={AllChangeRequest} />
									<MetronicRoute path="/customer/functional-specs" component={AllFunctionalSpec} />
									<MetronicRoute path="/team/company-standards" component={CompanyStandards} />
								{/** Customer Routes - End */}
								{/** Custom Pages - End */}
								<Redirect path="/organisational-chart" exact to="/organisational-chart/1" />
								<Redirect path="/" exact to={branding.landingPath} />
								<Route path="*" component={ErrorPage}/>
							</Switch>
						</Suspense>
					</ErrorBoundary>
				</Layout>
				</CacheGQL>
			</ConditionalRoute>
			{/* <Route path="*" component={ErrorPage}/> */}
		</Switch>
	);
}

export default Routes