import branding from '@config/branding'
import { useSession } from '@whitecobalt/tungsten/esm/components/Session'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import './index.scss'

interface CustomPublicContainerProps {

}

const CustomPublicContainer: React.FunctionComponent<CustomPublicContainerProps> = ({children}) => {
    const history = useHistory()
    const [{user}] = useSession()
     
    return (
        <div className="custom-public">
            <style>
                {`html, body { font-size: 1rem !important }`}
            </style>
            <link 
                rel="stylesheet" 
                id="google-fonts-1-css" 
                href="https://fonts.googleapis.com/css?family=Playfair+Display%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CSource+Serif+Pro%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CMontserrat%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&amp;ver=6.0.2&amp;display=swap" 
                type="text/css" 
                media="all"/>
            <header className="custom-public-header">
                <div className="custom-public-navbrand">
                    <img src={branding.logo.navbar} />
                </div>
                <nav className="custom-public-navbar">
                    <a className="custom-public-navbar-item" href="https://themailing.co/">Solutions</a>
                    <a className="custom-public-navbar-item" href="https://themailing.co/guides/">Learning</a>
                </nav>
                <nav className="custom-public-navaction">
                    {!user ? (
                        <Link className="custom-public-navaction-item" to="/auth/login">LOGIN</Link>
                    ) : (
                        <Link className="custom-public-navaction-item" to="/dashboard">DASHBOARD</Link>
                    )}
                </nav>
            </header>
            {children}
        </div>
    )
}

export default CustomPublicContainer