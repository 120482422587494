import React, { useMemo, useRef, useState } from "react";
import Grid, { GridReferenceType } from "@whitecobalt/tungsten/Grid";
import { TimeLogsGridQuery, TimeLogPropType } from "@services/query/TimeLogsQuery";
import { useScreenTitle } from "@whitecobalt/tungsten/hooks/useScreenTitle";
import branding from "@config/branding";
import Icon from '@whitecobalt/tungsten/esm/components/Icon';
import worldTrigger from 'world-trigger'
import Badge from 'react-bootstrap/Badge';
import './index.scss';
import { useSession } from "@whitecobalt/tungsten/esm/components/Session";
import { useQuery } from "@apollo/client";
import { emptyArray } from "@whitecobalt/tungsten/esm/common/utils/assets";
import ModalConfirm from "@whitecobalt/tungsten/esm/components/Modal/Confirm";
import { convertHoursToReadable, convertHoursToSeconds } from "@metronic/layout/components/extras/offcanvas/TimeLogs";
import { groupData, objectByIndex } from "@whitecobalt/tungsten/esm/common/utils/data";
import { DropdownQueryType, ProjectsDropdownQuery } from "@services/query/DropdownQueries";
import { useWorldTrigger } from "@whitecobalt/tungsten/esm/common/hooks/useWorldTrigger";
import ModalForm from "@whitecobalt/tungsten/esm/components/Modal/Form";
import FormManager from "@whitecobalt/tungsten/esm/components/FormManager";
import Hours from "@whitecobalt/tungsten/esm/components/Fields/Hours";
import SelectDropdown from "@whitecobalt/tungsten/esm/components/Fields/SelectDropdown";
import Toaster from "@whitecobalt/tungsten/esm/components/Toaster";
import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI";
import { useAuthParamAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthParamAPI";
import Button from "@whitecobalt/tungsten/esm/components/Button";
import { dateFormat, timeFormat } from "@whitecobalt/tungsten/esm/common/utils/dateFormat";
import Placeholder from "@whitecobalt/tungsten/esm/components/Placeholder";
import TaskField from "@metronic/layout/components/header/TimeTracker/TaskField";
import { darkenColor } from "@whitecobalt/tungsten/esm/common/utils/colors";
import DatePicker from "@whitecobalt/tungsten/esm/components/Fields/Date";
import { useGlobalStoreSelector } from "@services/store/global";

interface TimeLogProps {
    
}

const TimeLogs: React.FunctionComponent<TimeLogProps> = () => {
    useScreenTitle(`Time Logs - ${branding.brandName}`)
    const [{ user }] = useSession();
    const todayRaw = new Date()
    const [timeLogID] = useGlobalStoreSelector('timeLogID')
    const today = new Date(todayRaw.getFullYear(),  todayRaw.getMonth(), todayRaw.getDate(), 23, 59)
    const [more, setMore] = useState(new Date(todayRaw.getFullYear(),  todayRaw.getMonth(), todayRaw.getDate() - 7))
    const requestTaskTimeLog = useAuthParamAPI('/api/Task/timelog?id={id}', { method: 'POST' })
    const requestUpdate = useAuthAPI('/api/TimeLog/update')
    const requestDelete = useAuthParamAPI('/api/TimeLog/{id}')
    const requestProjects = useQuery<DropdownQueryType>(ProjectsDropdownQuery, {
        fetchPolicy: 'cache-first'
    })

    const request = useQuery<{ grid: { items: TimeLogPropType[] } }>(TimeLogsGridQuery, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: false,
        variables: {
            where: {
                UserID: {
                    equals: user?.id
                },
                StartDateTime: {
                    gte: more.toISOString(),
                    lte: today.toISOString()
                }
            },
            order: {
                EndDateTime: "DESC"
            }
        }
    })

    useWorldTrigger('time.log.reload', () => {
        request?.refetch()
    }, [request?.refetch])

    const handleUpdate = (event: any, item?: TimeLogPropType) => {
        event.preventDefault()
        event.stopPropagation()
        const isNew = typeof item === 'undefined'

        const date = item?.StartDateTime ? new Date(new Date(item?.StartDateTime).toDateString()) : undefined
        const startTime = item?.StartDateTime ? new Date(item?.StartDateTime).toLocaleTimeString('en-GB', { hour: "2-digit", minute: "2-digit", second: "2-digit" }): undefined
        const endTime = item?.EndDateTime ? new Date(item?.EndDateTime).toLocaleTimeString('en-GB', { hour: "2-digit", minute: "2-digit", second: "2-digit" }): undefined
        
        const recursivePromptForm = () => {
            ModalForm({
                key: "add-edit-timelog",
                title: `${isNew ? 'Add' : 'Edit'} Time Log`,
                titleIcon: `fa fa-${isNew ? 'plus' : 'pen'} fa-lg`,
                body: (
                    <>  
                         <div className="form-group">
                            <label>Time</label>
                            <FormManager.Row>
                                <FormManager.Col sm={4}>
                                    <FormManager.Input 
                                        as={DatePicker}
                                        name="logDate" 
                                        placeholder="01/11/1997"
                                        stripped
                                        value={date} />
                                </FormManager.Col>
                                <FormManager.Col sm={8}>
                                    <FormManager.Row>
                                        <FormManager.Col>
                                            <FormManager.Input 
                                                type="time"
                                                name="timeStart" 
                                                stripped
                                                rules="required"
                                                className="custom-time-field"
                                                value={startTime} />
                                        </FormManager.Col>
                                        <FormManager.Col>
                                            <FormManager.Input 
                                                type="time"
                                                name="timeEnd" 
                                                stripped
                                                rules="required"
                                                className="custom-time-field"
                                                value={endTime}/>
                                        </FormManager.Col>
                                    </FormManager.Row>
                                </FormManager.Col>
                            </FormManager.Row>
                        </div>
                        <FormManager.Input
                            label="What are you working on?"
                            name="description"
                            value={item?.Description}
                            rules="required"
                            length={100}
                            // description="The description of what you are working on"
                            />
                        <FormManager.Input 
                            as={SelectDropdown.Graph}
                            gql={ProjectsDropdownQuery}
                            fetchPolicy="cache-first"
                            label="Project"
                            name="metaData1" 
                            value={item?.MetaData1}
                            clearable={false}
                            rules="required_number"
                            manageOptions={(each: any) => {
                                const color = each.Color ? darkenColor(each.Color, 1) : undefined
                                return {
                                    ...each, 
                                    labelNode: (
                                        <div className="d-flex align-items-center" style={{color: color}}>
                                            <div className="bilot-bilot mr-2" style={{backgroundColor: each.Color || '#555'}}/>{each.label}
                                        </div>
                                    )
                                }
                            }}
                            // description="The project you are working on" 
                            />
                        <TaskField 
                            label="Task"
                            stripped={false}
                            value={item?.MetaData2} 
                            loading={false} />
                         <FormManager.Boolean 
                            name="metaData3" 
                            label="Investigation?"
                            checked={item?.MetaData3} />
                    </>
                ),
            }).then((event) => {
                
                if (event.isSubmit) {
                    if(event.isReady() === false) {
                        event.removeLoading()
                        recursivePromptForm()
                        return;
                    }

                    const FORM_DATA = event.json();
                    const startDateTime = new Date(`${FORM_DATA.logDate.toDateString()} ${FORM_DATA.timeStart || '00:00:00'}`)
                    const endDateTime = new Date(`${FORM_DATA.logDate.toDateString()} ${FORM_DATA.timeEnd || '00:00:00'}`)

                    if(startDateTime.getTime() > endDateTime.getTime()) {
                        endDateTime.setDate(endDateTime.getDate() + 1)
                    }
                    // const endDateTime = new Date(new Date(item?.StartDateTime!).getTime() + (convertHoursToSeconds((Hours.timeToNumber(FORM_DATA.editTime) as number)) * 1000))
                    const payload = {
                        "timeLogID": item?.ID,
                        "description": FORM_DATA.description,
                        "startDateTime": startDateTime,
                        "endDateTime": endDateTime,
                        "metaData1": FORM_DATA.metaData1 || 0,
                        "metaData2": FORM_DATA.metaData2 || null,
                        "metaData3": FORM_DATA.metaData3,
                        "metaData4": item?.MetaData4
                      }

                    const toast = Toaster(`Saving ${FORM_DATA.description}`);

                    requestUpdate
                        .call({ 
                            data: payload
                        })
                        .then((response) => {
                            if (response.data.success) {
                                event.closeModal();

                                toast.success(`Successfully saved ${FORM_DATA.description}`);
                                worldTrigger.dispatchTrigger('time.log.reload')
                                request.refetch()
                                if(FORM_DATA.metaData2) {
                                    requestTaskTimeLog.call({id: FORM_DATA.metaData2}).then(() => {
                                        worldTrigger.dispatchTrigger('task.reload')
                                    })
                                }
                            } else {
                                toast.fail(response.data.message || branding.messages.fail);
                                FormManager.setBackendValidation(event, response.data.errors);
                                event.setValidationMessages(response.data.errors?.map((error: any) => error.errorMessage))
                                recursivePromptForm();
                            }
                        })
                        .catch((error) => {
                            recursivePromptForm();
                            event.setError(error?.response?.data?.message || branding.messages.error);
                            toast.error();
                        })
                        .finally(() => {
                            event.removeLoading();
                        });
                    }
                });
        };
        recursivePromptForm();
    };

    const handleDelete = (event: any, item: TimeLogPropType) => {
        event.preventDefault()
        event.stopPropagation()
        const convertedHours = convertHoursToReadable(convertHoursToSeconds(item.Hours))
        ModalConfirm({
            titleIcon: "fa fa-trash",
            title: "Delete Time Log",
            body: (
                <p>You are <b className="text-danger">deleting</b> time log <b className="text-danger">{item.Description}</b> ({convertedHours})</p>
            )
        }).then((confirm) => {
            if(confirm) {
                const toast = Toaster(`Deleting ${item.Description} (${convertedHours})`)
                requestDelete.call({
                    id: item.ID
                }).then((response) => {
                    if(response.data.success === true) {
                        toast.success('Successfully deleted Time Log')
                        request.refetch()
                    } else {
                        toast.error(response.data.message || branding.messages.error)
                    }
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message || branding.messages.error)
                })
            }
        })
    }

    const data = useMemo(() => {
        const data = request.data?.grid.items || []
        const filtered = timeLogID ? data?.filter((item) => item.ID !== timeLogID) : data
        return filtered.reduce<Record<any, TimeLogPropType[]>>((result, item) => {
            const value = item.StartDateTime.split('T')[0]
            if (value in result) {
                  result[value].push(item);
            } else {
                  result[value] = [item];
            }
            return result;
        }, {})
    }, [timeLogID, request.data?.grid.items])

    const projectsByID = useMemo(() => objectByIndex('value', requestProjects.data?.dropdown.items || []), [requestProjects.data])

    return (
        <div id="time-log-list">
            {(request.data?.grid.items || []).length > 0 ? Object.keys(data).sort((a, b) => new Date(b).getTime() - new Date(a).getTime()).map((key) => {
                const dateRaw = new Date(key)
                const date = new Date(dateRaw.getFullYear(), dateRaw.getMonth(), dateRaw.getDate(), 23, 59)
                const timelogs = data[key]

                const totalSeconds = convertHoursToSeconds(timelogs.reduce((result, each) => each.Hours + result, 0))

                const totalHours = convertHoursToReadable(totalSeconds)

                return (
                    <div key={key} className="bg-white">
                        <Grid 
                            className="table table-head table-head-bg table-vertical-center table-hover mb-0"
                            data={timelogs}
                            totalCount={timelogs.length || 5}
                            defaultPerPage={5}
                            totalCountAsPerPage
                            showFooter={false}
                            facadeLoading={request.loading || requestProjects.loading}
                            rowAttr={(row: TimeLogPropType) => ({
                                className: 'clickable',
                                onClick: (event: any) => handleUpdate(event, row)
                            })}
                            columns={[
                                {
                                    label: today.toISOString() === date.toISOString() 
                                        ? 'Today' 
                                        : new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 23, 59).toISOString() === date.toISOString() 
                                            ? 'Yesterday' 
                                            : `${date.toDateString()}`,
                                    dataIndex: 'Description',
                                    render: (cell: string, row: TimeLogPropType) => {
                                        // @ts-ignore
                                        const color = projectsByID[row.MetaData1]?.Color || '#000'
                                        return (
                                            <div>
                                                {cell} {!!row.MetaData1 && <div><div className="bilot-bilot" style={{backgroundColor: color}}/><div className="mt-2" style={{color: darkenColor(color, 1)}}>{projectsByID[row.MetaData1]?.label || '-'}</div></div>}
                                            </div>
                                        ) 
                                    }
                                },
                                {
                                    label: "",
                                    dataIndex: 'Hours',
                                    render: (cell: number, row: TimeLogPropType) => {
                                        return (
                                            <div>
                                                {timeFormat(row.StartDateTime, 'en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} - {timeFormat(row.EndDateTime, 'en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                            </div>
                                        )
                                    },
                                    showColumnWhen: () => branding.services.env !== "chrome"
                                },
                                {
                                    label: `${totalHours}`,
                                    dataIndex: 'Hours',
                                    render: (cell: number, row: TimeLogPropType) => {
                                        const convertedHours = convertHoursToReadable(convertHoursToSeconds(cell))
                                        return (
                                            <div>
                                                {convertedHours}
                                            </div>
                                        )
                                    }
                                }
                            ]}
                            actions={[
                                {
                                    icon: 'fa fa-play',
                                    title: 'Start Again',
                                    onClick: (event, row: TimeLogPropType) => {
                                        event.preventDefault()
                                        event.stopPropagation()
                                        worldTrigger.dispatchTrigger('time.log.play', { description: row.Description, metaData1: row.MetaData1, metaData2: row.MetaData2, metaData3: row.MetaData3 })
                                    }
                                },
                                {
                                    icon: 'fa fa-pen',
                                    title: 'Edit Log',
                                    onClick: handleUpdate
                                },
                                {
                                    icon: 'fa fa-trash',
                                    title: 'Delete log',
                                    onClick: handleDelete
                                }
                            ]}
                            />
                    </div>
                )
            }) : request.loading === false && (
                <div className="bg-white p-10 mb-5 text-center">
                    <div className="mb-3"><Icon name="inbox" size={25}/></div>
                    No time log recorded from <b>{more.toDateString().substr(4)}</b> to <b>{today.toDateString().substr(4)}</b>
                </div>
            )}
            {request.loading && (
                <div className="bg-white">
                    <Grid 
                        className="table table-head table-head-bg table-vertical-center table-hover mb-0"
                        data={emptyArray}
                        totalCount={5}
                        defaultPerPage={5}
                        totalCountAsPerPage
                        showFooter={false}
                        facadeLoading
                        columns={[
                            {
                                label: () => <Placeholder noMargin loading style={{width: 100, borderRadius: 5}}/>,
                                dataIndex: 'Description'
                            },
                            {
                                label: `00:00:00`,
                                dataIndex: 'Hours'
                            }
                        ]}
                        actions={[
                            {
                                icon: 'fa fa-play',
                                onClick: (event, row: TimeLogPropType) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    worldTrigger.dispatchTrigger('time.log.play', { description: row.Description, metaData1: row.MetaData1, metaData2: row.MetaData2, metaData3: row.MetaData3 })
                                }
                            },
                            {
                                icon: 'fa fa-pen',
                                onClick: handleUpdate
                            },
                            {
                                icon: 'fa fa-trash',
                                onClick: handleUpdate
                            }
                        ]}
                        />
                </div>
            )}
            {branding.services.env !== 'chrome' && (
                <>
                    <div className="text-center mb-4">
                        {more.toDateString().substr(4)} - {today.toDateString().substr(4)}
                    </div>
                    <div className="d-flex justify-content-center">
                        <Button type="button" disabled={request.loading} onClick={() => {
                            setMore(new Date(more.getFullYear(), more.getMonth(), more.getDate() - 7))
                        }}>
                            Load More
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default TimeLogs;
