import { gql } from "@apollo/client";
import { UserPropType } from "../UsersQuery";

export type BasicDescriptionPropTypes = {
    ID: number;
    Description: string;
}

export type TargetCompanyContactPropTypes = {
    ID: number;
    TargetCompanyID: number;
    EmailAddress: string;
    Firstname: string;
    Surname: string;
    JobTitle: string;
    LinkedIn: string;
    MobileNumber: string;
    Notes: string;
    PhoneNumber: string;
    BirthdayDay: number;
    BirthdayMonth: number;
    BirthdayYear: number;
    GenderID: number;
    PersonalNotes: string
    TitleID: number;
    Title:{
        Value: {
            ID: number
            Description: string;
        }
    };
    Archived: boolean;
    IgnoreEmails: boolean;
    EmailAddressValid: boolean | null;
    LastEmailAddressValidation: string;
}

export type TargetCompanyAttachmentPropTypes = {
    ID: number;
    FileKey: string;
    DateTimeStamp: Date;
    Title: string;
    UserID: number;
    User: {
        Value: UserPropType;
    }
}

export type TargetCompanyHistoryPropTypes = {
    ID: number;
    TargetCompanyID: number;
    DateTimeStamp: Date;
    Details: string;
    TargetCompanyContactID: number;
    CallSucceeded: boolean;
    CallAttempted: boolean;
    TargetCompanyContact: {
        Value: TargetCompanyContactPropTypes
    }
    UserID: number;
    EmailID: number;
    User: {
        Value: UserPropType;
    }
    TargetCompanyAttachments: TargetCompanyAttachmentPropTypes[]
}

export type TargetCompanyPropTypes = {
    ID: number;
    AddedDate: Date;
    AppointmentDateTime: string;
    Address1: string;
    Address2: string;
    Address3: string;
    Address4: string;
    CompanyName: string;
    TradingAs: string;
    TargetCompanyContactIDs: number[];
    TargetCompanyContacts: TargetCompanyContactPropTypes[];
    DefaultTargetCompanyContactID: number;
    DefaultTargetCompanyContact: {
        Value: TargetCompanyContactPropTypes;
    }
    EmailAddress: string;
    EmployeeDesc: string;
    LastActionDate: Date;
    NextActionDate: Date;
    FollowUpNote: string;
    NextActionUserID: number;
    NextActionUser: {
        Value: UserPropType;
    }
    Notes: string;
    OrganisationID: number;
    OtherClosedReason: string;
    PhoneNumber: string;
    Postcode: string;
    RegistrationNumber: string;
    TargetCompanyCategoryID: number;
    Tags: string[];
    TargetCompanyCategory: {
        Value: BasicDescriptionPropTypes;
    }
    TargetCompanyGroupID: number;
    TargetCompanyGroup: {
        Value: BasicDescriptionPropTypes;
    }
    TargetCompanyClosedReasonID: number;
    TargetCompanyClosedReason: {
        Value: BasicDescriptionPropTypes;
    }
    TargetCompanySourceID: number;
    TargetCompanySource: {
        Value: TargetCompanySourcePropType;
    }
    TargetCompanyStatusID: number;
    TargetCompanyStatus: {
        Value: BasicDescriptionPropTypes & {
          TagColour: string | null;
        };
    }
    TargetCompanyHistoryIDs: number[];
    TargetCompanyHistorys: TargetCompanyHistoryPropTypes[]
    WebsiteAddress: string;
    TurnoverDesc: string;
    TargetCompanyAttachmentIDs: number[];
    TargetCompanyAttachments: TargetCompanyAttachmentPropTypes[];
}

export type TargetCompanySourcePropType = {
    ID: number;
    Description: string;
    AgreementFileKey: string;
    EmailAddress: string;
    IntroducerFeeStructureID: number;
    IntroducerFeeStructure: {
        Value: IntroducerFeeStructurePropType;
    }
    IsIntroducer: boolean;
}

export type IntroducerFeeStructurePropType = {
    ID: number;
    Archived: boolean;
    Description: string;
    MonthlyLTE250: number;
    MonthlyLTE500: number;
    MonthlyLTE1000: number;
    MonthlyLTE5000: number;
    MonthlyOver5000: number;
}

export const TargetCompanyGridQuery = gql`
query(
    $skip: Int,
    $take: Int,
    $order: [TargetCompanySortInput!],
    $where: TargetCompanyFilterInput,
    $companyName: String,
    $contactName: String,
    $noLastActionDate: Boolean,
    $phoneNumber: String,
    $tagFilter: String,
  ) {
    grid: TargetCompanys(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where,
      CompanyName: $companyName
      ContactName: $contactName
      NoLastActionDate: $noLastActionDate,
      PhoneNumber: $phoneNumber,
      TagFilter: $tagFilter
    ) {
      totalCount
      items {
        ID
        AddedDate
        AppointmentDateTime
        CompanyName
        TradingAs
        DefaultTargetCompanyContactID
        DefaultTargetCompanyContact {
            Value {
                ID
                Firstname
                Surname
                Title {
                    Value {
                        Description
                    }
                }
            }
        }
        EmployeeDesc
        LastActionDate
        NextActionDate
        NextActionUserID
        NextActionUser {
            Value {
                ID
                Firstname
                Surname
                Title
            }
        }
        OrganisationID
        PhoneNumber
        RegistrationNumber
        TargetCompanyCategoryID
        TargetCompanyCategory {
            Value {
                Description
            }
        }
        TargetCompanyGroup {
            Value {
                Description
            }
        }
        TargetCompanyClosedReasonID
        TargetCompanySourceID
        TargetCompanyStatusID
        TargetCompanyStatus {
              Value {
                Description
                TagColour
            }
        }
        TurnoverDesc
        Tags
      }
    }
  }
`;

export const TargetCompanyContactsGridQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $where: TargetCompanyContactFilterInput,
    $order: [TargetCompanyContactSortInput!]
  ){
    grid: TargetCompanyContacts(
      skip: $skip,
      take: $take,
      where: $where,
      order: $order
    ){
      totalCount
      items {
        ID
        EmailAddress
        Firstname
        Surname
        JobTitle
        LinkedIn
        MobileNumber
        Notes
        PhoneNumber
        TitleID
        Title {
            Value {
                ID
                Description
            }
        }
        Archived
        IgnoreEmails
        BirthdayDay
        BirthdayMonth
        BirthdayYear
        GenderID
        PersonalNotes
        EmailAddressValid
        LastEmailAddressValidation
      }
    }
  }
`

export const TargetCompanyContactsQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $where: TargetCompanyContactFilterInput,
    $order: [TargetCompanyContactSortInput!]
  ){
    contacts: TargetCompanyContacts(
      skip: $skip,
      take: $take,
      where: $where,
      order: $order
    ){
      totalCount
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`

export const TargetCompanyAttachmentsGridQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $where: TargetCompanyAttachmentFilterInput,
    $order: [TargetCompanyAttachmentSortInput!]
  ){
    grid: TargetCompanyAttachments(
      skip: $skip,
      take: $take,
      where: $where,
      order: $order
    ){
      totalCount
      items {
        ID
        FileKey
        DateTimeStamp
        Title
        UserID
      }
    }
  }
`

export const TargetCompanyHistoriesGridQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $where: TargetCompanyHistoryFilterInput,
    $order: [TargetCompanyHistorySortInput!]
  ){
    grid: TargetCompanyHistorys(
      skip: $skip,
      take: $take,
      where: $where,
      order: $order
    ){
      totalCount
      pageInfo {
        hasNextPage
      }
      items {
        ID
        DateTimeStamp
        Details
        TargetCompanyContactID
        CallAttempted
        CallSucceeded
        UserID
        EmailID
        User {
            Value {
                ID
                Firstname
                Surname
            }
        }
        TargetCompanyContact {
            Value {
                ID
                EmailAddress
                Firstname
                Surname
                JobTitle
                MobileNumber
                Notes
                PhoneNumber
                TitleID
                Title {
                    Value {
                        ID
                        Description
                    }
                }
            }
        }
        TargetCompanyAttachments {
          ID
          FileKey
          Title
        }
      }
    }
  }
`
export const TargetCompanySourcesGridQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $where: TargetCompanySourceFilterInput,
    $order: [TargetCompanySourceSortInput!]
  ){
    grid: TargetCompanySources(
      skip: $skip,
      take: $take,
      where: $where,
      order: $order
    ){
      totalCount
      items {
        ID
        Description
        EmailAddress
      }
    }
  }
`

export const TargetCompanyScreenQuery = gql`
query($id: Int!) {
    screen: TargetCompany(iD: $id) {
        ID
        AddedDate
        AppointmentDateTime
        Address1
        Address2
        Address3
        Address4
        CompanyName
        TradingAs
        TargetCompanyContactIDs
        DefaultTargetCompanyContactID
        EmailAddress
        EmployeeDesc
        LastActionDate
        NextActionDate
        NextActionUserID
        Notes
        OrganisationID
        OtherClosedReason
        PhoneNumber
        Postcode
        RegistrationNumber
        TargetCompanyCategoryID
        TargetCompanyClosedReasonID
        TargetCompanyGroupID
        TargetCompanySourceID
        TargetCompanyStatusID
        TargetCompanyHistoryIDs
        WebsiteAddress
        TurnoverDesc
        TargetCompanyAttachmentIDs
        FollowUpNote
        Tags
    }
}`

export type TargetCompanyContactPageViewPropType = {
  ID: number;
  DateTimeStamp: string;
  FullURL: string;
  PageName: string;
  TargetCompanyContact: {
    Value?: {
      Firstname: string;
      Surname: string;
      EmailAddress: string;
    }
  }
}

export const TargetCompanyContactPageViewsQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $where: TargetCompanyContactPageViewFilterInput,
    $order: [TargetCompanyContactPageViewSortInput!],
    $targetCompanyID: Int
  ){
    grid: TargetCompanyContactPageViews(
      skip: $skip,
      take: $take,
      where: $where,
      order: $order,
      TargetCompanyID: $targetCompanyID
    ){
      totalCount
      items {
        ID
        DateTimeStamp
        FullURL
        PageName
        WebsiteDomain
        TargetCompanyContact {
          Value {
            Firstname
            Surname
            EmailAddress
          }
        }
      }
    }
  }
`


export type TargetCompanyGroupPropType = {
    ID: number;
    Description: string;
}

export const TargetCompanyGroupsGridQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $order: [TargetCompanyGroupSortInput!],
    $where: TargetCompanyGroupFilterInput
  ) {
    grid: TargetCompanyGroups(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        ID
        Description
      }
    }
  }
`;

export const TargetCompanyContactsDropDownQuery = gql`
query(
  $companyName: String,
  $targetCompanyGroupID: Int,  
  $targetCompanyCategoryID: Int,
  $targetCompanyStatusID: Int,
  $tagFilter: String,
  $where: TargetCompanyContactFilterInput, 
  $order: [TargetCompanyContactSortInput!],
  $take: Int,
  $skip: Int
  ) {
    draggable: TargetCompanyContacts(
      CompanyName: $companyName,
      TargetCompanyGroupID: $targetCompanyGroupID,
      TargetCompanyCategoryID: $targetCompanyCategoryID,
      TargetCompanyStatusID: $targetCompanyStatusID,
      where: $where, 
      order: $order,
      take: $take,
      skip: $skip
    ) {
      items {
        ID
        Firstname
        Surname
        TargetCompanyID
        TargetCompany {
          Value {
            CompanyName
            TargetCompanyGroup {
              Value {
                Description
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const TargetCompanyContactsSmallDropdownQuery = gql`
query(
  $where: TargetCompanyContactFilterInput,
  $order: [TargetCompanyContactSortInput!],
  ) {
  dropdown: TargetCompanyContacts(
      where: $where, 
      order: $order,
  ) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const TargetCompanyGroupsDropDownQuery = gql`
query($where: TargetCompanyGroupFilterInput, $order: [TargetCompanyGroupSortInput!]) {
    dropdown: TargetCompanyGroups(where: $where, order: $order) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TargetCompanyCategoriesDropDownQuery = gql`
query($where: TargetCompanyCategoryFilterInput, $order: [TargetCompanyCategorySortInput!]) {
    dropdown: TargetCompanyCategories(where: $where, order: $order) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TargetCompanyStatusesDropDownQuery = gql`
query($where: TargetCompanyStatusFilterInput, $order: [TargetCompanyStatusSortInput!]) {
    dropdown: TargetCompanyStatuses(where: $where, order: $order) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TargetCompanyClosedReasonsDropDownQuery = gql`
query($where: TargetCompanyClosedReasonFilterInput, $order: [TargetCompanyClosedReasonSortInput!]) {
    dropdown: TargetCompanyClosedReasons(where: $where, order: $order) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TargetCompanySourcessDropDownQuery = gql`
query($where: TargetCompanySourceFilterInput, $order: [TargetCompanySourceSortInput!]) {
    dropdown: TargetCompanySources(where: $where, order: $order) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TargetCompanyCustomerDropdownQuery = gql`
query($where: OrganisationFilterInput, $order: [OrganisationSortInput!]) {
    dropdown: OrganisationsByType(where: $where, order: $order, OrganisationTypeID: 3) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;


export const IntroducerFeeDropdown = gql`
  query($where: IntroducerFeeStructureFilterInput) {
    dropdown: IntroducerFeeStructures(where: $where, order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;
