import ErrorBoundary from '@whitecobalt/tungsten/esm/components/ErrorBoundary'
import Loader from '@whitecobalt/tungsten/esm/components/Loader'
import React, { lazy, Suspense } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { ErrorPage } from '../ErrorPage'
import CustomPublicContainer from './Container'
// const SectorReports = lazy(() => import('./Pages/SectorReports'))

interface CustomPublicProps {

}

const CustomPublic: React.FunctionComponent<CustomPublicProps> = (props) => {
    const { path } = useRouteMatch();

    return (
        <Suspense fallback={(<Loader active />)}>
            <ErrorBoundary>
                <CustomPublicContainer>
                    <Switch>
                        {/* <Route path={`${path}/sector-reports/:id`} component={SectorReports}/> */}
                        <Route path="*" component={ErrorPage}/>
                    </Switch>
                </CustomPublicContainer>
            </ErrorBoundary>
        </Suspense>
    )
}

export default CustomPublic