import React from 'react'
import FormManager from "@whitecobalt/tungsten/esm/components/FormManager"
import EditorField from "@whitecobalt/tungsten/esm/components/Fields/EditorField"
import branding from "@config/branding";
import { useEditorFieldFileUpload } from "@whitecobalt/tungsten/esm/common/hooks/useEditorFieldFileUpload";

interface EmailSignatureFieldProps {
    value?: any;
}

const EmailSignatureField: React.FunctionComponent<EmailSignatureFieldProps> = ({ value }) => {
    const uploadCallback = useEditorFieldFileUpload((response) => {
        return `${branding.services.endpoint}${response[0]}`
    })

    return (
        <FormManager.Input
            as={EditorField}
            required
            name="emailSignature"
            value={value}
            hasRawValue
            className="edit-wrapper-body"
            overideToolBar={{
                options: ["inline", "list", "link", "history", "image"],
                image: {
                    urlEnabled: true,
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    uploadCallback,
                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                    defaultSize: {
                        height: 'auto',
                        width: '100%',
                    },
                }
            }}
        />
    )
}

export default EmailSignatureField