import branding from '@config/branding'
import { useSession } from '@whitecobalt/tungsten/esm/components/Session'
import { useEffect } from 'react'
import OneSignal from 'react-onesignal'

export const useOneSignal = () => {
    const [{user}] = useSession()
    useEffect(() => {
        if(branding.services.onesignal && !!user) {
            OneSignal.init({ 
                appId: branding.services.onesignal, 
                persistNotification: false, 
                welcomeNotification: { disable: true }
            })
        }
    }, [user])
}