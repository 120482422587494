import { gql } from "@apollo/client";
/* CUSTOMS - START */
export const BusinessProcessDiagramDraggableQuery = gql`
  query($where: BusinessProcessDiagramFilterInput) {  
    draggable: BusinessProcessDiagrams(where: $where) {
      items {
        ID
        Title
        Description
        Department {
            Value {
                Name
            }
        }
      }
    }
  }
`;

export const TestMatrixDraggableQuery = gql`
  query($where: TestMatrixFilterInput) {  
    draggable: TestMatrices(where: $where) {
      items {
        ID
        TestCaseName
        TestMatrixStatusID
      }
    }
  }
`;

export const TaskDraggableQuery = gql`
  query($projectID: Int, $where: TaskFilterInput) {  
    draggable: Tasks(ProjectID: $projectID, where: $where) {
      items {
        ID
        Title
        TaskStatus {
          Value {
            Description
          }
        }
      }
    }
  }
`;

export const ChangeRequestDraggableQuery = gql`
  query($where: ChangeRequestFilterInput) {  
    draggable: ChangeRequests(where: $where) {
      items {
        ID
        Title
      }
    }
  }
`;

export const FeatureDraggableQuery = gql`
  query($where: FeatureFilterInput) {  
    draggable: Features(where: $where) {
      items {
        ID
        Title
        FeatureStatus {
          Value {
            Description
          }
        }
      }
    }
  }
`;
/* CUSTOMS - END */