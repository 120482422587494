import { useSession } from "@whitecobalt/tungsten/esm/components/Session"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useOrg } from "./useOrg"

export const useRouteCorrection = () => {
    const [{user}] = useSession()
    const { isCustomer, isTeamMember } = useOrg()
    const history = useHistory()
    const location = history.location

    useEffect(() => {
        if(!user) return;

        if(location.pathname.startsWith('/customer/projects') && isTeamMember) {
            history.replace({
                pathname: location.pathname.replace('/customer/projects', '/developmentmanagement'),
                hash: location.hash,
                search: location.search
            })
        } else if(location.pathname.startsWith('/developmentmanagement') && isCustomer) {
            history.replace({
                pathname: location.pathname.replace('/developmentmanagement', '/customer/projects'),
                hash: location.hash,
                search: location.search
            })
        } else if(user && location.pathname.startsWith('/customer/') && isTeamMember) {
            history.replace('/error/error')
        } else if((user && location.pathname.startsWith('/auth/') === false && location.pathname.startsWith('/customer/') === false && location.pathname.startsWith('/process/diagrams/department/') === false && ['/dashboard', '/mycompany'].includes(location.pathname) === false) && isCustomer) {
            history.replace('/error/error')
        }

    }, [location.pathname, user])
}