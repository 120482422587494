import React from 'react';
import Card from "react-bootstrap/Card";
import Icon from '@whitecobalt/tungsten/Icon';
import branding from '@config/branding';
import "@whitecobalt/tungsten/esm/components/GridScreen/index.scss";
import { useScreenTitle } from '@whitecobalt/tungsten/hooks/useScreenTitle';
import FormManager from '@whitecobalt/tungsten/esm/components/FormManager';
import { FormEvent } from 'formydable';
import { useAuthAPI } from '@whitecobalt/tungsten/esm/common/hooks/useAuthAPI';
import { useSession } from '@whitecobalt/tungsten/esm/components/Session';
import Toaster from '@whitecobalt/tungsten/esm/components/Toaster';
import FileManagerField from '@whitecobalt/tungsten/esm/components/Fields/FileManagerField';

const origin = window.location.host.startsWith('localhost') ? 'http://uat.odin.whitecobalt.net' : window.location.origin

const host = window.location.host.startsWith('localhost') ? 'uat.odin.whitecobalt.net' : window.location.host

interface HTMLToPDFExampleProps {
    
}

const HTMLToPDFExample: React.FunctionComponent<HTMLToPDFExampleProps> = () => {
    useScreenTitle(`Image Manager DEMO - ${branding.brandName}`)
    const [{token}] = useSession()
    const request = useAuthAPI('/api/htmltopdf')
    const handler = (event: FormEvent) => {
        if(event.isReady()) {
            const FORM_DATA = event.json()

            const payload = {
                "url": FORM_DATA.url,
                "responseHeaders": {
                    "Accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
                    "Accept-Encoding": "gzip, deflate",
                    "Accept-Language": "en-US,en;q=0.9",
                    "Cache-Control": "max-age=0",
                    "Connection": "keep-alive",
                    "If-Modified-Since": "Mon, 25 Sep 2023 14:03:39 GMT",
                    "If-None-Match": "1740aba3f7a0b6c89620eb88b9372efb",
                    "Upgrade-Insecure-Requests": "1",
                    "User-Agent": window.navigator.userAgent
                },
                "cookies": [
                    {
                        "name": "odinADTK",
                        "value": token,
                        "domain": host
                    }
                ]	
            }

            const toast = Toaster('Converting HTML to PDF')
            request.call({
                data: payload
            })
            .then((response) => {
                if(response.data.url) {
                    const filename = response.data.url.match(/public\/(.+?)\?/)[1]
                    toast.success(filename)
                    window.open(response.data.url, '_blank')
                } else {
                    toast.fail()
                }
            })
            .catch((error) => toast.error(error))
        }
    }

    return (
        <Card className="grid-screen">
            <Card.Header className="bg-grey">
                <div className="grid-screen-title">
                    <Icon name="file-image" />
                    <h1>Image Manager DEMO</h1>
                </div>
            </Card.Header>
            <Card.Body className="bg-grey">
                <FormManager.Row>
                    <FormManager.Col sm={6}>
                        <FileManagerField name="sample" onChange={() => {}}/>
                    </FormManager.Col>
                </FormManager.Row>
            </Card.Body>
        </Card>
    )
}

export default HTMLToPDFExample
