import { createStore, useStore, useStoreSelectorState } from "shomai"

const MFA_LS_KEY = 'hasMFAPrompt'
export interface GlobalStore {
    timeLogID: number | null;
    menuListLoaded: boolean;
    hasMFAPrompt: boolean;
}

export const globalStore = createStore<GlobalStore>({
    timeLogID: null,
    menuListLoaded: false,
    hasMFAPrompt: localStorage.getItem(MFA_LS_KEY) === "true",
})

export const useGlobalStore = () => {
    return [useStore(globalStore, (state) => state), globalStore.setState]
}

export const useGlobalStoreSelector = <P = GlobalStore[keyof GlobalStore]>(selector: keyof GlobalStore) => useStoreSelectorState<P>(globalStore, selector)

export const useHasMFAPrompt = (): [boolean, (callback: boolean | ((state: boolean) => boolean)) => void] => {
    const [hasMFAPrompt, setHasMFAPrompt] = useGlobalStoreSelector<boolean>('hasMFAPrompt')

    return [hasMFAPrompt, (callback) => {
        const data = typeof callback === 'function' ? callback(hasMFAPrompt) : callback
        localStorage.setItem(MFA_LS_KEY, data.toString())
        setHasMFAPrompt(data)
    }]
}