import { useSession } from '@whitecobalt/tungsten/Session'

export const useOrg = () => {
    const [{user}] = useSession()
    const organisationID = user?.organisationIDs?.[0] || 0
    return {
        isCustomer: organisationID !== 1,
        isTeamMember: organisationID === 1,
        organisationID
    }
}